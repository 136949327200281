@charset "UTF-8";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: roboto;
  background-color: #f1ede8;
  background-image: url(lines-mobiles.2b37a6d5.png);
  background-repeat: no-repeat;
  background-position: top;
}
.scrolled .navShadow {
  opacity: 1;
}
.navShadow {
  display: none;
  box-shadow: 0px 4px 4px rgba(183, 178, 172, 0.15);
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s;
  z-index: -1;
}
.navBar {
  display: flex;
  justify-content: space-between;
  height: 92px;
  align-items: center;
  top: 0;
  z-index: 1;
  position: fixed;
  width: 100%;
  background-color: rgba(17, 25, 67, 0.95);
}
.burger {
  display: none;
}
.logo {
  display: none;
  margin-left: 5vw;
}
.logoMobile img {
  height: 50px;
}
.logoMobile {
  display: block;
}
.underNav {
  background-color: #111943;
  width: 100%;
  height: 92px;
}
.menu {
  margin-right: 0;
}
.menu ul {
  list-style: none;
  display: flex;
  align-items: center;
}
.menu ul li {
  display: block;
}
.menu ul li a {
  display: none;
  text-decoration: none;
  color: #111943;
  position: relative;
}
.menu ul li a::after {
  content: "";
  display: block;
  z-index: -1;
  top: 6px;
  left: 3px;
  width: 0%;
  height: 10px;
  margin-top: 3px;
  background-color: #eeb7b5;
  transition: width 0.2s ease-in-out;
  position: absolute;
}
.menu ul li a:hover::after {
  width: 100%;
}
.menu ul li a.current::after {
  width: 100%;
}
.contact {
  position: relative;
}
.btnContact {
  cursor: pointer;
  height: 40px;
  width: 159px;
  color: #111943;
  background-color: #eeb7b5;
  border: 2px solid #111943;
  border-radius: 4px;
  font-family: roboto;
  font-weight: 400;
  font-size: 16px;
  margin-right: 16px;
}
.contactsFix {
  position: fixed;
  display: flex;
  flex-direction: column;
  margin-top: 20vh;
  margin-left: 2.5vw;
  transform: translateX(-50%);
  align-items: center;
}
.containerFix {
  display: none;
  width: 30px;
}
.media {
  cursor: pointer;
  width: 30px;
  margin-bottom: 24px;
}
.contactFix {
  transform-origin: left;
}
.hero {
  height: calc(100vh - 92px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(to bottom, rgba(64, 64, 64, 0.46), rgba(255, 255, 255, 0.2)), url(bg-mobile.adaa05d2.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}
.heroImg {
  display: none;
  position: relative;
  z-index: -1;
  transform: translateY(20%);
  opacity: 0;
  -webkit-animation: heroImg 1s ease-in-out forwards 0.5s;
          animation: heroImg 1s ease-in-out forwards 0.5s;
}
.heroContent {
  display: flex;
  position: relative;
  font-size: 0;
  left: 2vw;
  width: 70vw;
}
.mainTitle {
  position: absolute;
  font-size: 63px;
  font-weight: 500;
  color: #ec8181;
  text-shadow: -3px 2px 0px #abd4d4;
  top: 0;
  left: 0;
  line-height: 74px;
  width: 270px;
  -ms-grid-row-align: center;
      align-self: center;
}
.mainTitle span {
  opacity: 0;
  transition: all 0.5s ease;
  transform: translateY(50px);
  display: inline-block;
}
span.fade {
  opacity: 1;
  transform: translateY(0px);
}
@-webkit-keyframes heroImg {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes heroImg {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.rectangleOne {
  position: absolute;
  background-color: #111943;
  width: 274px;
  height: 39px;
  top: 10%;
  left: 6%;
  opacity: 0;
  transform: translateX(-20%);
  -webkit-animation: rectangles 0.6s ease-in-out forwards 0.5s;
          animation: rectangles 0.6s ease-in-out forwards 0.5s;
}
.rectangleTwo {
  position: absolute;
  background-color: #111943;
  width: 274px;
  height: 39px;
  top: 33%;
  left: 6%;
  opacity: 0;
  transform: translateX(-20%);
  -webkit-animation: rectangles 0.6s ease-in-out forwards 1s;
          animation: rectangles 0.6s ease-in-out forwards 1s;
}
.rectangleThree {
  position: absolute;
  background-color: #111943;
  width: 180px;
  height: 39px;
  top: 56%;
  left: 6%;
  opacity: 0;
  transform: translateX(-20%);
  -webkit-animation: rectangles 0.6s ease-in-out forwards 1.5s;
          animation: rectangles 0.6s ease-in-out forwards 1.5s;
}
@-webkit-keyframes rectangles {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes rectangles {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.bluumStroke {
  transform: translateY(50%);
  margin-left: 60vw;
  stroke: white;
}
.bluumStroke path:nth-child(1) {
  stroke-dasharray: 388;
  stroke-dashoffset: 388;
  -webkit-animation: line-anim 1.5s ease forwards 1.6s;
          animation: line-anim 1.5s ease forwards 1.6s;
}
.bluumStroke path:nth-child(2) {
  stroke-dasharray: 233;
  stroke-dashoffset: 233;
  -webkit-animation: line-anim 1.5s ease forwards 1.9s;
          animation: line-anim 1.5s ease forwards 1.9s;
}
.bluumStroke path:nth-child(3) {
  stroke-dasharray: 343;
  stroke-dashoffset: 343;
  -webkit-animation: line-anim 1.5s ease forwards 2.2s;
          animation: line-anim 1.5s ease forwards 2.2s;
}
.bluumStroke path:nth-child(4) {
  stroke-dasharray: 343;
  stroke-dashoffset: 343;
  -webkit-animation: line-anim 1.5s ease forwards 2.5s;
          animation: line-anim 1.5s ease forwards 2.5s;
}
.bluumStroke path:nth-child(5) {
  stroke-dasharray: 548;
  stroke-dashoffset: 548;
  -webkit-animation: line-anim 1.5s ease forwards 2.8s;
          animation: line-anim 1.5s ease forwards 2.8s;
}
@-webkit-keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}
.discoverBtn {
  position: absolute;
  bottom: 35px;
  text-decoration: none;
  color: #f1ede8;
}
.discoverBtn:focus {
  outline: none;
}
.discoverBtn:before {
  position: absolute;
  bottom: calc(50% - 25px);
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  content: "";
  display: block;
  border: 2px solid #f1ede8;
  width: 12px;
  height: 12px;
  border-width: 0 0 2px 2px;
  border-radius: 3px;
}
/* Section à Propos */
.aProposBg {
  background-image: url(service-section.675ddf10.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}
.aPropos {
  margin: 0 16px;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.aProposTitle {
  max-width: 500px;
  margin: 0 auto;
}
@media (min-width: 700px) {
  .aProposTitle {
    max-width: 600px;
    justify-content: space-between;
  }
}
@media (min-width: 1100px) {
  .aProposTitle {
    -ms-grid-row-align: center;
        align-self: center;
    margin-right: 24px;
    width: 40%;
  }
}
.aProposTitle h2 {
  margin: 0 auto;
  margin-top: 36px;
  position: relative;
  font-weight: 500;
  font-size: 26px;
  color: #111943;
  line-height: 39px;
}
@media (min-width: 700px) {
  .aProposTitle h2 {
    font-size: 30px;
    margin: auto 0;
    width: 370px;
  }
}
.prop1 {
  position: absolute;
  z-index: -1;
  left: 3%;
  top: 23%;
  width: 295px;
  height: 20px;
  background: rgba(236, 129, 129, 0.5);
}
@media (min-width: 700px) {
  .prop1 {
    width: 340px;
  }
}
.prop2 {
  position: absolute;
  z-index: -1;
  left: 3%;
  top: 58px;
  width: 295px;
  height: 20px;
  background: rgba(236, 129, 129, 0.5);
}
@media (min-width: 700px) {
  .prop2 {
    width: 340px;
  }
}
.aProposDescr {
  margin-top: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}
.aProposBtn {
  cursor: pointer;
  font-size: 16px;
  color: #111943;
  width: 100%;
  background-color: #eeb7b5;
  border: 2px solid #111943;
  height: 40px;
  border-radius: 4px;
  margin-top: 24px;
  margin-bottom: 24px;
  padding-top: 3px;
}
@media (min-width: 700px) {
  .aProposBtn {
    width: 230px;
  }
}
.linkOffre {
  position: relative;
}
.underBtnApropos {
  margin-top: 24px;
  width: 230px;
  height: 40px;
  background-color: #abd4d4;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
@media (min-width: 1100px) {
  .aProposBtn:hover {
    transform: translate(5px, -5px);
  }
}
.aProposImg {
  margin-top: 72px;
  width: 100%;
  height: auto;
  display: none;
}
/* Section nos services */
.nosServices {
  padding: 24px 0;
  padding-bottom: 36px;
  margin: 0 auto;
  width: 90%;
  min-height: 790px;
  position: relative;
}
@media (min-width: 1100px) {
  .nosServices {
    min-height: 800px;
  }
}
.nsTittle {
  margin: 0 auto;
  text-align: left;
  margin-top: 24px;
  position: relative;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  color: #111943;
  width: 300px;
}
@media (min-width: 700px) {
  .nsTittle {
    text-align: center;
    font-size: 30px;
    width: 600px;
  }
}
.ns1 {
  position: absolute;
  z-index: -1;
  left: 150px;
  top: 18px;
  width: 280px;
  height: 20px;
  transform: translateX(-50%);
  background: rgba(236, 129, 129, 0.5);
}
@media (min-width: 700px) {
  .ns1 {
    left: 310px;
    width: 530px;
  }
}
.ns2 {
  position: absolute;
  z-index: -1;
  left: 96px;
  top: 57px;
  width: 170px;
  height: 20px;
  transform: translateX(-50%);
  background: rgba(236, 129, 129, 0.5);
}
.tags {
  display: none;
  color: #bbb;
}
.nsCaroussel {
  height: 70%;
  margin-bottom: 36px;
}
@media (min-width: 700px) {
  .nsCaroussel {
    width: 600px;
    position: relative;
    margin: 0 auto;
  }
}
@media (min-width: 1100px) {
  .nsCaroussel {
    margin-left: 0;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media (min-width: 1100px) {
  .caroussel-container {
    margin-left: 10vw;
    display: flex;
    flex-wrap: wrap;
  }
}
.slide {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 64px;
  align-items: center;
}
@media (min-width: 700px) {
  .slide {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.slide h3 {
  font-weight: 500;
}
.nsImg {
  width: 150px;
  height: 95px;
  margin-bottom: 24px;
}
.nsSubTittle {
  width: 300px;
  text-align: center;
  color: #111943;
  font-size: 24px;
}
.nsContentDesktop {
  display: none;
}
@media (min-width: 700px) {
  .nsContentDesktop {
    display: block;
  }
}
.nsContentMobile {
  display: block;
}
@media (min-width: 700px) {
  .nsContentMobile {
    display: none;
  }
}
.nsContent {
  margin: 0 auto;
  margin-top: 12px;
  max-width: 450px;
  font-size: 16px;
  text-align: left;
  line-height: 24px;
}
@media (min-width: 700px) {
  .nsContent {
    text-align: left;
    margin-bottom: 12px;
    max-width: 100%;
  }
}
@media (min-width: 700px) {
  .prev,
.next {
    cursor: pointer;
    width: 50px;
    padding: 16px;
    margin-top: -22px;
    color: #282828;
    font-weight: 500;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
}
.prev {
  left: 20%;
  margin-right: 15px;
}
.next {
  margin-left: 15px;
  right: 20%;
  border-radius: 3px 0 0 3px;
}
.dots {
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 3px;
  align-items: center;
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
}
@media (min-width: 700px) {
  .dots {
    display: block;
    text-align: center;
  }
}
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}
.tag {
  cursor: pointer;
}
.dot:hover,
.activeD {
  background-color: #717171;
}
.active,
.tag:hover {
  color: #111943;
}
/* tablette */
@media screen and (min-width: 700px) {
  .mainTitle {
    font-size: 80px;
    width: 350px;
    line-height: 90px;
  }

  .rectangleOne {
    width: 350px;
    height: 60px;
    left: 4%;
  }

  .rectangleTwo {
    width: 350px;
    height: 60px;
    top: 38%;
    left: 4%;
  }

  .rectangleThree {
    width: 250px;
    height: 60px;
    top: 65%;
    left: 4%;
  }

  .nsBg {
    background-size: 100vw 100vh;
  }

  .ns2 {
    display: none;
  }

  .nsImg {
    width: 120px;
    height: 80px;
    margin-bottom: 0px;
  }

  .nsSubTittle {
    margin-left: 12px;
    font-size: 30px;
    width: 75%;
    text-align: left;
  }
}
/* Desktop */
@media screen and (min-width: 1100px) {
  body {
    background-image: url(lines.e51307c1.png);
  }

  .ancre {
    height: 90px;
    width: 100%;
    position: absolute;
    bottom: 0%;
    left: 0;
    z-index: -1;
  }

  .burger {
    display: none;
  }

  .navBar {
    background-color: #f1ede8;
  }

  .underNav {
    height: 92px;
  }

  .navShadow {
    display: block;
  }

  .logo {
    display: block;
  }

  .logoMobile {
    display: none;
  }

  .menu {
    margin-right: 5vw;
  }

  .menu ul li {
    display: block;
    margin-left: 4vw;
    font-size: 16px;
    font-weight: 500;
  }

  .menu ul li a {
    display: block;
  }

  .btnContact {
    margin-right: 0;
  }

  .underBtnContact {
    width: 159px;
    height: 40px;
    background-color: #abd4d4;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }

  .btnContact:hover {
    transform: translate(5px, -5px);
  }

  .containerFix {
    display: block;
  }

  .hero {
    background-image: none;
  }

  .heroContent {
    width: auto;
  }

  .heroImg {
    display: block;
  }

  .mainTitle {
    font-size: 90px;
    text-shadow: -4px 3px 0px #abd4d4;
    top: -15%;
    left: -12%;
    line-height: 105px;
    width: 800px;
    -ms-grid-row-align: center;
        align-self: center;
  }

  .discoverBtn {
    color: #111943;
  }

  .discoverBtn:before {
    border: 2px solid #111943;
    border-width: 0 0 2px 2px;
    border-radius: 3px;
  }

  .rectangleOne {
    width: 750px;
    height: 70px;
    top: -6%;
    left: -8%;
    transform: translateX(-20%);
  }

  .rectangleTwo {
    width: 250px;
    height: 70px;
    top: 20%;
    left: -8%;
    opacity: 0;
    transform: translateX(-20%);
  }

  .rectangleThree {
    display: none;
  }

  .bluumStroke {
    position: absolute;
    right: -15%;
    top: -25%;
    margin-bottom: 30px;
    margin-left: 25px;
    stroke: #ec8181;
  }

  /* section 2 (A propos) */
  .aPropos {
    margin: 0 10vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0;
    position: relative;
  }

  .aProposImg {
    display: block;
    width: 55%;
    margin-bottom: 24px;
  }

  .aProposBtn {
    width: 230px;
  }

  /* Section nos services */
  .aProposBg {
    background-size: 100vw 100vh;
  }

  .ns2 {
    display: none;
  }

  .tags {
    display: block;
    width: 25%;
    margin-top: 150px;
    position: -webkit-sticky;
    position: sticky;
  }

  .tag {
    margin-top: 40px;
  }

  .nsImg {
    width: 120px;
    height: 80px;
    margin-bottom: 0px;
  }

  .nsSubTittle {
    margin-left: 0px;
    margin-top: 20px;
    font-size: 36px;
    width: 100%;
    text-align: left;
  }
}
/* Section projet */
.project {
  margin: 0 10px;
  position: relative;
  padding-top: 24px;
  padding-bottom: 48px;
}
@media (min-width: 700px) {
  .project {
    margin: 0;
  }
}
.projTitle {
  margin: 0 auto;
}
@media (min-width: 700px) {
  .projTitle {
    width: 343px;
  }
}
.projTitle h2 {
  font-weight: 500;
  font-size: 26px;
  margin-bottom: 24px;
  position: relative;
}
@media (min-width: 700px) {
  .projTitle h2 {
    width: 343px;
  }
}
.proj1 {
  position: absolute;
  top: 13px;
  left: 8px;
  width: 290px;
  height: 20px;
  background-color: #f0b9b7;
  z-index: -1;
}
@media (min-width: 700px) {
  .proj1 {
    width: 300px;
    top: 13px;
  }
}
.boxes {
  display: flex;
  flex-direction: column;
  gap: 12px;
  scrollbar-width: none;
}
@media (min-width: 700px) {
  .boxes {
    display: -ms-grid;
    display: grid;
    gap: 12px;
    -ms-grid-columns: 550px 12px 450px 12px 450px 12px 450px;
    grid-template-columns: 550px repeat(3, 450px);
    -ms-grid-rows: auto 12px auto;
        grid-template-areas: "a b d f" "a c e g";
    padding: 0 10vw;
    overflow-x: scroll;
  }
}
.boxes::-webkit-scrollbar {
  display: none;
}
.box-item {
  width: 100%;
  height: 350px;
  overflow: hidden;
}
@media (min-width: 700px) {
  .box-item {
    height: 300px;
  }
}
.projImg.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  transform: scale(1.06);
}
.projImg1.active {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  transform: scale(1.06);
}
.projImg {
  width: 100%;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 700px) {
  .projImg {
    height: 300px;
  }
}
@media (min-width: 1100px) {
  .projImg {
    transition: all 0.2s;
    transform: scale(1);
    will-change: transform;
  }
}
.box1 {
  grid-area: a;
  height: 350px;
  width: 100%;
}
@media (min-width: 700px) {
  .box1 {
    height: 100%;
  }
}
@media (min-width: 1100px) {
  .box1 {
    position: relative;
  }
}
.projImg1 {
  width: 100%;
  height: 350px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (min-width: 700px) {
  .projImg1 {
    height: 612px;
  }
}
@media (min-width: 1100px) {
  .projImg1 {
    transition: all 0.2s;
    transform: scale(1);
    will-change: transform;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    z-index: -1;
  }
}
.box1Hover {
  display: none;
}
@media screen and (min-width: 1100px) {
  .projImg1:hover {
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -ms-transform: scale(1.02);
    -o-transform: scale(1.02);
  }

  .box1Hover {
    display: block;
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .projImg1:hover {
    opacity: 0.4;
  }

  .testimonial {
    display: flex;
    flex-direction: row;
    background-color: #f1ede8;
    border-radius: 4px;
    padding: 14px;
  }

  .content-testim {
    width: 270px;
    text-align: left;
    margin-left: 6px;
  }

  .title-testim {
    font-size: 16px;
    font-weight: bold;
  }

  .title-testim span {
    font-weight: normal;
  }

  .text-testim {
    text-align: left;
    font-size: 12px;
  }

  .logo-testim {
    width: 72px;
    height: 72px;
  }

  .box1:hover .box1Hover {
    opacity: 1;
  }
}
.box2 {
  grid-area: b;
}
.box3 {
  grid-area: c;
}
.box4 {
  grid-area: d;
}
.box5 {
  grid-area: e;
}
.box6 {
  grid-area: f;
}
.box7 {
  grid-area: g;
}
@media (min-width: 700px){
  .box1 {
    -ms-grid-row: 1;
    -ms-grid-row-span: 3;
    -ms-grid-column: 1;
  }
  .box2 {
    -ms-grid-row: 1;
    -ms-grid-column: 3;
  }
  .box3 {
    -ms-grid-row: 3;
    -ms-grid-column: 3;
  }
  .box4 {
    -ms-grid-row: 1;
    -ms-grid-column: 5;
  }
  .box5 {
    -ms-grid-row: 3;
    -ms-grid-column: 5;
  }
  .box6 {
    -ms-grid-row: 1;
    -ms-grid-column: 7;
  }
  .box7 {
    -ms-grid-row: 3;
    -ms-grid-column: 7;
  }
}
@media (min-width: 1100px) {
  .formBg {
    background-image: url(form-bg.64de452c.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: 900px;
    margin: 48px auto;
    padding: 24px;
  }
}
.form {
  margin: 0 auto;
  padding-bottom: 48px;
}
@media (min-width: 1100px) {
  .form {
    width: 100%;
    background-color: #f1ede8;
    display: flex;
    flex-direction: row-reverse;
    padding: 24px;
    justify-content: space-between;
  }
}
.contactUs {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px 0;
}
.contactUsTitle {
  margin: 0 auto;
  position: relative;
  margin-bottom: 36px;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
}
.form1 {
  position: absolute;
  z-index: -1;
  top: 45%;
  left: 15px;
  width: 285px;
  height: 20px;
  background: rgba(236, 129, 129, 0.5);
}
.formSubTitle {
  font-size: 34px;
  line-height: 51px;
  color: #ec8181;
  margin-bottom: 36px;
  text-align: center;
  font-weight: bold;
}
@media (min-width: 1100px) {
  .formSubTitle {
    text-align: left;
    width: 40%;
    margin: auto 0;
    margin-left: 10%;
  }
}
.contact-form {
  margin: 0 auto;
  width: 90vw;
}
@media (min-width: 700px) {
  .contact-form {
    width: 70vw;
  }
}
@media (min-width: 1100px) {
  .contact-form {
    width: 50%;
  }
}
.input-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
label {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  color: #111943;
}
input {
  background: #f7f7f7;
  height: 40px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 10px;
  color: #414141;
  font-family: Lato;
  font-size: 14px;
  line-height: 19px;
}
textarea {
  background: #f7f7f7;
  height: 134px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 10px;
  font-family: Lato;
  font-size: 14px;
  line-height: 19px;
}
.form-btn {
  padding-top: 2px;
  background: #111943;
  border-radius: 4px;
  height: 35px;
  width: 90vw;
  color: #f1ede8;
  border: none;
  font-size: 16px;
}
@media (min-width: 700px) {
  .form-btn {
    width: 70vw;
  }
}
@media (min-width: 1100px) {
  .form-btn {
    width: 100%;
  }
}
.succes {
  color: green;
}
.error {
  color: red;
}
.footer {
  background: #111943;
  height: 230px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 24px 0;
}
@media (min-width: 700px) {
  .footer {
    flex-direction: row;
    padding: 0 36px;
    align-items: center;
  }
}
.networks {
  width: 100px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  -ms-grid-row-align: center;
      align-self: center;
}
.logoFooter {
  height: 70px;
  -ms-grid-row-align: center;
      align-self: center;
}
.mail {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #f1ede8;
}
/*# sourceMappingURL=index.68915dfa.css.map */
